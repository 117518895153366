<template>
  <div class="container">
    <edit-header
      entity="discount"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew">
    </edit-header>
    <form
      v-disable-all="!can(uiPermissions.DISCOUNTS_UPDATE)"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row">
        <div class="col">
          <div data-test="card-discount" class="card">
            <div class="card-header">
              <h3 class="card-title">Discount</h3>
              <div v-if="!isNew" class="card-options">
                <label data-test="toggle-has-been-used" class="custom-switch m-0">
                  <input
                    v-model="item.used"
                    type="checkbox"
                    class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">Has been used</span>
                </label>
              </div>
            </div>
            <div class="card-body">
              <div data-test="group-user" class="form-group">
                <label class="form-label">User</label>
                <user-selector v-model="item.customer" :required="true"></user-selector>
              </div>
              <div class="form-group">
                <label class="form-label">Amount</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">AED</span>
                  </div>
                  <input
                    v-model="item.amount"
                    type="number"
                    data-test="txt-amount"
                    class="form-control"
                    required
                    min="0"/>
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">Reason (optional)</label>
                <input
                  v-model="item.reason"
                  type="text"
                  data-test="txt-reason"
                  class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="discount"
            :isNew="isNew"
            :submitting="submitting"
            :hide-delete="!can(uiPermissions.DISCOUNTS_DELETE)"
            @delete="handleDelete">
          </edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import UserSelector from '@/components/UserSelector';
import edit from '@/mixins/edit';
import {discounts} from '@/services';

export default {
  components: {
    EditFooter,
    EditHeader,
    UserSelector,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      item: {
        amount: 1,
        customer: null,
        reason: '',
        used: false,
      },
    };
  },
  computed: {
    route() {
      return `/discounts/${this.item.id}`;
    },
  },
  methods: {
    fetchData(id) {
      return discounts.getById(id);
    },
    async handleDelete() {
      if (window.confirm('Deleting store credit cannot be undone. Are you sure that you want to delete this store credit?')) {
        await discounts.deleteById(this.item.id);
        this.$router.push('/store-credit');
      }
    },
    submitData(item) {
      const payload = Object.assign({}, item, {
        amount: Number(item.amount),
        customer: item.customer.id,
      });
      return discounts.saveOrUpdate(payload);
    },
  },
};

</script>
